<template>
  <div class="repaymentInfo">
    <mt-header :title="$t('repayment')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="bar">
      <div class="title">{{ $t('repaymentAmount') }}</div>
      <mt-button plain>{{ item.repayAmount | formatMoney }}</mt-button>
      <div class="tips">{{ $t('repaymentTips') }}</div>
    </div>
    <div class="message">
      <div>{{ $t('repaymentLoanMsg1') }}</div>
      <div>{{ $t('repaymentLoanMsg2') }}</div>
    </div>
    <div class="content">
      <div @click="clickRepayment('SPEI')">
        <mt-cell :title="$t('tasaTramitacion')" is-link>
          <img slot="icon" class="bank" src="@/assets/spei.png">
          <span style="color: #4F9B41">0</span>
          <span>MXN</span>
        </mt-cell>
      </div>
      <div @click="clickRepayment('OXXO')">
        <mt-cell :title="$t('tasaTramitacion')" is-link>
          <img slot="icon" class="bank" src="@/assets/oxxo.png">
          <span style="color: #4F9B41">27</span>
          <span>MXN</span>
        </mt-cell>
      </div>
    </div>
    <mt-popup
      v-model="popupVisible"
    >
      <div class="box">
        <div class="title">{{ $t('remainingAmountDue') }} {{ item.repayAmount | formatMoney }}</div>
        <div class="tips">{{ $t('applicationRepayment') }}</div>
        <div class="content">
          <mt-field
            :label="$t('amount')"
            :state="amountState"
            :placeholder="$t('amountPlaceholder')"
            v-model="amount"
          ></mt-field>
        </div>
        <div class="button">
          <mt-button type="primary" size="large" @click="submit">{{
            $t("confirm")
          }}</mt-button>
          <mt-button type="default" size="large" @click="popupVisible = false">{{
            $t("cancel")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>

    <mt-popup v-model="popupNullVisible" :closeOnClickModal="false">
      <div class="box">
        <div class="title">{{ $t('notHaveLoan') }}</div>
        <div class="button">
          <mt-button type="primary" size="large" @click="popupNullVisible = false; $router.go(-1)">{{
            $t("confirm")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { contractDetail, getUserInfo } from '../../utils/api'
export default {
  name: 'RepaymentInfo',
  components: {
    callPhone
  },
  data() {
    return {
      popupVisible: false,
      popupNullVisible: false,
      amount: '0',
      type: '',
      item: {}
    }
  },
  computed: {
    ...mapState(["user", "queryStatus"]),
    amountState() {
      if (this.amount > this.item.repayAmount || this.amount <= 0) {
        return 'error'
      }
      return 'success'
    },
    applyId() {
      return this.$route.query.applyId
    },
  },
  watch: {
    popupVisible(val) {
      if (val) {
        this.amount = this.item.repayAmount
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations(["SET_USER", "SET_QUERY_STATUS"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.getUser();
      switch (this.user.applyStatus) {
        case "NORMAL":
        case "OVERDUE":
          await this.getContractDetail();
          break;
        default:
          this.popupNullVisible = true
          break;
      }
      this.$indicator.close();
      this.$NProgress.done()
    },
    async getContractDetail() {
      await this.$axios({
        method: 'post',
        url: contractDetail,
        params: {
          applyId: this.applyId
        }
      })
        .then((e) => {
          if (e.status.code == '000') {
            this.item = e.body
          }
        })
        .catch(() => {})
    },
    async getUser() {
      await this.$axios({
        method: "POST",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code == "000") {
          this.SET_USER(e.body);
        }
      }).catch(() => {})
    },
    clickRepayment(type) {
      this.type = type;
      this.popupVisible = true;
    },
    async submit() {
      if (this.amountState != 'success') {
        return this.$toast(this.$t('amountPlaceholder'));
      }
      this.$router.push({ path: '/mine/repaymentCode', query: {
          applyId: this.applyId,
          type: this.type,
          amount: this.amount
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.repaymentInfo {
  .bar {
    min-height: 120px;
    background: #4F9B41;
    text-align: center;
    color: white;
    .title {
      padding-top: 10px;
    }
    .is-plain {
      color: white;
      border: 1px solid white;
      min-width: 200px;
      margin: 10px 0px;
    }
    .tips {
      font-size: 12px;
    }
  }
  .message {
    padding: 10px;
    font-size: 12px;
    div {
      padding-top: 5px;
    }
  }
  .bank {
    height: 20px;
    margin: 0 5px;
  }
  /deep/ .mint-popup {
    .box {
      min-width: 300px;
      padding: 10px;
      .title {
        font-weight: 600;
        padding-bottom: 10px;
      }
      .tips {
        font-size: 12px;
        padding: 5px;
      }
      .button {
        padding-top: 20px;
        display: flex;
        button {
          margin: 0px 10px;
        }
      }
    }
  }
}
</style>
